import * as Yup from "yup";

import {networkCaptionMaxLength, networkDescriptionMaxLength, networkTitleMaxLength} from "../../components";
import {PostServices} from "../../../../data";

const getOpengraphSchema = (n: PostServices) => ({
	comment: Yup.string()
		.notRequired()
		.test("comment-length", "Caption too long", value => (value?.length || 0) <= networkCaptionMaxLength[n])
		.test("comment-required", "Caption is required", (value, schema) => {
			const context = schema?.options?.context || {};
			const {image, video} = context.opengraphs?.[n] || {};
			const shareType = context.url ? "url" : image || video ? "media" : "text";
			if (shareType === "media") return true;
			return (
				!!context.url ||
				(n === "general" && context.perNetwork) ||
				!!value?.length ||
				!context.opengraphs?.[n]
			);
		}),
	video: Yup.string().notRequired(),
	image: Yup.string().notRequired(),
	title: Yup.string()
		.test(
			"title-length",
			"Title too long",
			value => !networkTitleMaxLength[n] || (value?.length || 0) <= (networkTitleMaxLength[n] ?? 0)
		)
		.notRequired(),
	description: Yup.string()
		.test("description-length", "Description too long", (value, schema) => {
			const context = schema?.options?.context || {};
			const {video} = context.opengraphs?.[n] || {};

			if (!(context.url || video)) return true;

			return !networkDescriptionMaxLength[n] || (value?.length || 0) <= (networkDescriptionMaxLength[n] ?? 0);
		})

		.notRequired(),
});

type ValidationSchemaProps = {skipContentValidation?: boolean};

export const validationSchema = (props?: ValidationSchemaProps) =>
	Yup.object().shape({
		recipient: Yup.object()
			.shape({
				networks: Yup.array().of(Yup.string()).min(1, "Network is required"),
			})
			.required("Recipient is required"),
		...(!props?.skipContentValidation
			? {
					opengraphs: Yup.object().shape({
						general: Yup.object().shape(getOpengraphSchema("general")),
						facebook: Yup.object().shape(getOpengraphSchema("facebook")).notRequired(),
						linkedin: Yup.object().shape(getOpengraphSchema("linkedin")).notRequired(),
						twitter: Yup.object().shape(getOpengraphSchema("twitter")).notRequired(),
					}),
			  }
			: {}),
		schedule: Yup.object().shape({
			general: Yup.object().shape({
				scheduledFor: Yup.date().notRequired().min(new Date(), "Schedule time must be in the future"),
			}),
			facebook: Yup.object()
				.shape({
					scheduledFor: Yup.date()
						.notRequired()
						.min(new Date(), "Schedule time for Facebook must be in the future"),
				})
				.notRequired(),
			linkedin: Yup.object()
				.shape({
					scheduledFor: Yup.date()
						.notRequired()
						.when("result", (value, schema) =>
							!value ? schema.min(new Date(), "Schedule time for LinkedIn must be in the future") : schema
						),
				})
				.notRequired(),
			twitter: Yup.object()
				.shape({
					scheduledFor: Yup.date().notRequired().min(new Date(), "Schedule time for X must be in the future"),
				})
				.notRequired(),
		}),
	});
