import {useCallback, useMemo, useRef, useState} from "react";
import {Formik} from "formik";
import dayjs from "dayjs";
import {useLocalStorage} from "react-use";
import isEqual from "lodash/isEqual";

import {CreatePostForm} from "./form/create-post-form";
import {EditPostForm} from "./form/edit-post-form";
import {validationSchema} from "./form/validation-schema";
import {
	CREATE_SHARE,
	OpenGraph,
	Service,
	services,
	Share,
	SHARE_IMMEDIATELY,
	UPDATE_SHARE,
	UPDATE_SHARE_EVENT,
	useMyUser,
} from "../../../data";
import {useMutationToast} from "../../../toast";
import {FormValues} from "./form/types";
import {useNewModal as useModal} from "../../../modals";
import {SharingResults} from "../components";
import {UserShareEventsResult} from "../../../data/share";
import {REGISTER_PAGE_EVENT} from "../../../data/badges";
import {Setter} from "../../../types";

interface PersonalPostProps {
	share: Share;
	shareFromUrl?: string;
	setShareFromUrl: Setter<string | undefined>;
	onComplete: () => void;
	disabled?: boolean;
	disableContentEditing?: boolean;
}

export const postCookieDeserializer = availableNetworks => val => {
	const vals = JSON.parse(val);
	const scheduleCopy = {...vals.schedule};
	const recipient = vals?.recipient?.id;
	if (Object.values(scheduleCopy).length !== 0) {
		[...services, "general"].forEach(s => {
			if (Object.values(availableNetworks).length !== 0 && !availableNetworks?.[recipient].includes(s)) {
				delete scheduleCopy[s];
			}
			if (scheduleCopy?.[s]?.scheduledFor) {
				scheduleCopy[s].scheduledFor = dayjs(scheduleCopy[s].scheduledFor);
			}
		});
	}
	let filteredRecipientNetworks = vals?.recipient?.networks ?? [];
	if (vals?.recipient?.networks && Object.values(availableNetworks).length !== 0) {
		filteredRecipientNetworks = vals.recipient.networks.filter(n => availableNetworks[recipient].includes(n));
	}
	return {
		...vals,
		recipient: vals.recipient
			? {
					...vals.recipient,
					networks: filteredRecipientNetworks,
			  }
			: null,
		schedule: scheduleCopy,
		url: vals.url || undefined,
		id: vals.id || undefined,
	};
};

export const PersonalPost = ({
	share,
	shareFromUrl,
	setShareFromUrl,
	onComplete,
	disabled,
	disableContentEditing,
}: PersonalPostProps) => {
	const me = useMyUser();

	const {id, opengraphs, url} = share ?? {};
	const events = useMemo(
		() =>
			share.shareEvents.filter(
				se => !se.scheduledFor || se.scheduledFor.isAfter() || !se.sharedAt || (se.sharedAt && !se.result)
			),
		[share.shareEvents]
	);
	const shareNetworks = useMemo(() => events.map(ev => ev.network), [events]);
	const availableNetworks = useMemo(
		() => services.filter(s => me.connections[s === "facebook" ? "facebookPage" : s]?.connected),
		[me.connections]
	);
	const [newPostCookie, setNewPost, remove] = useLocalStorage(`new-post-personal-${me.id}`, null, {
		raw: false,
		serializer: JSON.stringify,
		deserializer: postCookieDeserializer({[me.id]: availableNetworks}),
	});
	const initialNewPostCookie = useRef(newPostCookie);
	const [results, setResults] = useState<UserShareEventsResult | undefined>();
	const {modal: shareResultsModal, open: openShareResultsModal, close: closeShareResultsModal} = useModal({});
	const [createShare, {loading: creating}] = useMutationToast(CREATE_SHARE);
	const [updateShare, {loading: saving}] = useMutationToast(UPDATE_SHARE);
	const [updateShareEvent, {loading: updating}] = useMutationToast(UPDATE_SHARE_EVENT);
	const [shareImmediately, {loading: sharing}] = useMutationToast(SHARE_IMMEDIATELY);
	const [registerPageEvent] = useMutationToast(REGISTER_PAGE_EVENT);

	const updateShareEvents = useCallback(
		async values => {
			const {schedule} = values;
			const promises = [] as Promise<object>[];

			Object.keys(schedule ?? {}).forEach(network => {
				const ev = events.find(ev => ev.network === network);
				if (!ev?.id || dayjs(schedule[network].scheduledFor).isSame(ev.scheduledFor)) return;
				promises.push(
					updateShareEvent({
						variables: {
							id: ev.id,
							scheduledFor: schedule[network].scheduledFor,
						},
					})
				);
			});

			return Promise.all(promises);
		},
		[updateShareEvent, events]
	);
	const maybeUpdatePost = useCallback(
		async (values: FormValues) => {
			if (me.role === "user" && me.org.options?.preventPostEditing && share.fromCollection) return share;
			const {opengraphs, perNetwork} = values;
			const changes = {} as {opengraphs?: Record<Service, OpenGraph>; url?: string | null};
			const deletions = {} as {opengraphs?: Record<Service, boolean>};

			const updatedOpengraphs = Object.entries(opengraphs).reduce((acc, [n, opengraph]) => {
				if (Object.entries(opengraph ?? {}).some(([property, v]) => share.opengraphs[n]?.[property] !== v)) {
					let newData = {} as OpenGraph;
					const isMediaShare = (opengraphs[n].image || opengraphs[n].video) && !values.url;
					const hasCustomTitle = ["Text Share", "Image Share", "Video Share", ""].includes(
						opengraphs[n]?.title ?? ""
					);

					if (isMediaShare && hasCustomTitle) {
						newData = {title: `${opengraphs[n].video ? "Video" : "Image"} Share`};
					}

					acc[n] = {
						...opengraphs[n],
						...newData,
					};
				}

				return acc;
			}, {} as Record<Service, OpenGraph>);

			if (Object.keys(updatedOpengraphs).length) {
				changes.opengraphs = updatedOpengraphs;
			}

			const deletedOpengraphs = (perNetwork
				? (Object.keys(opengraphs) as Service[])
						.filter(n => services.includes(n) && share.opengraphs[n] && !opengraphs[n])
						.reduce((acc, n) => ({...acc, [n]: true}), {})
				: {}) as Record<Service, boolean>;

			if (Object.keys(deletedOpengraphs).length) {
				deletions.opengraphs = deletedOpengraphs;
			}

			if (!Object.keys(changes).length && !Object.keys(deletions).length && values.url === share.url) {
				return share;
			}

			const result = await updateShare({
				variables: {
					id: share.id,
					changes,
					deletions,
					...(values.url !== share.url ? {url: values.url} : {}),
				},
			});

			return result?.data?.updateShare;
		},
		[updateShare, share, me.org.options?.preventPostEditing, me.role]
	);

	const update = useCallback(
		async values => {
			await updateShareEvents(values);

			return maybeUpdatePost(values);
		},
		[maybeUpdatePost, updateShareEvents]
	);

	const createPost = useCallback(
		async (values: FormValues) => {
			const {opengraphs, recipient, schedule, url} = values;

			const result = await createShare({
				variables: {
					networks: recipient.networks.map(n => ({
						network: n,
						scheduledFor: schedule.immediately
							? dayjs().add(5, "minutes")
							: schedule?.[n]?.scheduledFor ?? new Date(),
						peakTime:
							!schedule.immediately &&
							!schedule?.[n]?.scheduledFor &&
							(schedule?.[n]?.peakTime ?? me.peakTime),
					})),
					url,
					opengraphs: Object.keys(opengraphs).reduce((acc, n) => {
						if (opengraphs[n]) {
							let newData = {} as OpenGraph;
							const isMediaShare = (opengraphs[n].image || opengraphs[n].video) && !values.url;
							const hasCustomTitle = ["Text Share", "Image Share", "Video Share", ""].includes(
								opengraphs[n]?.title ?? ""
							);

							if (isMediaShare && hasCustomTitle) {
								newData = {title: `${opengraphs[n].video ? "Video" : "Image"} Share`};
							}

							acc[n] = {
								title: "Text Share",
								...opengraphs[n],
								...newData,
							};
						}

						return acc;
					}, {}),
					timezone: dayjs.tz.guess(),
				},
			});

			if (url) {
				registerPageEvent({
					variables: {
						type: "pastelink",
						userId: me.id,
					},
				});
			}

			return Promise.resolve(result?.data?.createShare);
		},
		[createShare, registerPageEvent, me.peakTime, me.id]
	);

	const maybeShareImmediately = useCallback(
		async (values, share) => {
			if (!values.schedule.immediately || !share?.id) {
				return Promise.resolve();
			}

			const result = await shareImmediately({variables: {id: share.id}});

			setResults(result?.data?.shareImmediately);
			openShareResultsModal();
		},
		[shareImmediately, openShareResultsModal]
	);

	const onSubmit = useCallback(
		async (values: FormValues, {setSubmitting}) => {
			setSubmitting(true);

			let result;

			if (share.id) {
				result = await update(values);
			} else {
				result = await createPost(values);
			}

			await maybeShareImmediately(values, result);

			setSubmitting(false);
			remove();
		},
		[createPost, update, share, maybeShareImmediately, remove]
	);

	const recipientOptions = useMemo(
		() => [
			{
				label: me.fullName,
				id: String(me.id),
				networks: availableNetworks,
				schedule: availableNetworks.reduce(
					(acc, n) => ({
						...acc,
						[n]: {
							id: events.find(ev => ev.network === n)?.id,
							scheduledFor: undefined,
							peakTime: me.peakTime,
							timeslot: !me.peakTime,
						},
					}),
					{}
				),
			},
		],
		[me.fullName, me.id, me.peakTime, availableNetworks, events]
	);
	const socialScoreValue = useMemo(
		() => share.shareEvents.filter(se => !se.sharedAt).reduce((acc, item) => acc + item.socialScore, 0),
		[share.shareEvents]
	);
	const socialScoreSuggestion = useMemo(() => share.smartScoreSuggestions?.general[0]?.message, [
		share.smartScoreSuggestions,
	]);
	const potentialSocialScore = useMemo(() => {
		const potential =
			socialScoreValue + share.smartScoreSuggestions?.general.reduce((acc, item) => acc + item.value, 0);
		return (socialScoreValue / potential || 0) * 100;
	}, [socialScoreValue, share.smartScoreSuggestions?.general]);

	const perNetwork = useMemo(() => !!id || services.some(s => share.opengraphs[s]), [share.opengraphs, id]);
	const formProps = useMemo(
		() => ({
			recipientOptions,
			socialScore: {
				value: socialScoreValue,
				potential: potentialSocialScore,
				suggestion: socialScoreSuggestion,
			},
			shareFromUrl,
			setShareFromUrl,
			onClose: () => {
				remove();
				onComplete();
			},
			disabled: disabled || creating || saving || updating || sharing,
		}),
		[
			disabled,
			creating,
			saving,
			updating,
			sharing,
			socialScoreValue,
			potentialSocialScore,
			socialScoreSuggestion,
			recipientOptions,
			shareFromUrl,
			setShareFromUrl,
			onComplete,
			remove,
		]
	);

	const emptyValues = useMemo(
		() => ({
			id: undefined,
			opengraphs: {
				general: {
					comment: "",
				},
			},
			url: undefined,
			perNetwork: false,
			activeNetwork: perNetwork ? services.find(s => !!share.opengraphs[s]) : "general",
			recipient: {
				id: String(me.id),
				networks: availableNetworks,
				peakTime: me.peakTime,
			},
			schedule: availableNetworks.reduce(
				(acc, network) => ({
					...acc,
					[network]: {
						scheduledFor: null,
						peakTime: me.peakTime,
						timeslot: !me.peakTime,
					},
				}),
				{}
			),
		}),
		[availableNetworks, me, perNetwork, share.opengraphs]
	);
	const initialValues = useMemo(
		() =>
			({
				id,
				opengraphs,
				url,
				perNetwork,
				activeNetwork: perNetwork ? services.find(s => !!share.opengraphs[s]) : "general",
				recipient: {
					id: String(me.id),
					networks: share.id ? shareNetworks : availableNetworks,
					peakTime: me.peakTime,
				},
				schedule: events.length
					? events.reduce(
							(acc, ev) => ({
								...acc,
								[ev.network]: {
									id: ev.id,
									scheduledFor: ev.scheduledFor,
									result: ev.result ?? false,
								},
							}),
							{}
					  )
					: availableNetworks.reduce(
							(acc, network) => ({
								...acc,
								[network]: {
									scheduledFor: null,
									peakTime: me.peakTime,
									timeslot: !me.peakTime,
								},
							}),
							{}
					  ),
			} as FormValues),
		[id, opengraphs, url, perNetwork, share, me.id, me.peakTime, availableNetworks, shareNetworks, events]
	);

	const isEmpty = initialValues && isEqual(initialValues, emptyValues);
	const onSharingResultsClose = useCallback(() => {
		closeShareResultsModal();
		onComplete();
	}, [closeShareResultsModal, onComplete]);

	const initialFormValues = useMemo(
		() =>
			id
				? initialValues
				: initialNewPostCookie.current && isEmpty
				? initialNewPostCookie.current
				: initialValues,
		[id, initialValues, isEmpty]
	);

	return (
		<>
			<Formik
				initialValues={initialFormValues}
				validationSchema={validationSchema({
					skipContentValidation:
						me.role === "user" && me.org.options?.preventPostEditing && share.fromCollection,
				})}
				validateOnChange
				validateOnMount={!isEmpty}
				onSubmit={onSubmit}
			>
				{share?.id ? (
					<EditPostForm share={share} {...formProps} disableContentEditing={disableContentEditing} />
				) : (
					<CreatePostForm
						initialValues={initialValues}
						hasUnsavedChanges={
							!!initialNewPostCookie.current && !isEqual({...initialNewPostCookie.current}, initialValues)
						}
						setCachePost={setNewPost}
						disableContentEditing={disableContentEditing}
						{...formProps}
					/>
				)}
			</Formik>
			<SharingResults modal={shareResultsModal} onClose={onSharingResultsClose} results={results} />
		</>
	);
};
