import React, {FC, ReactElement, useCallback, useEffect, useMemo, useState} from "react";
import {useDrag, useDrop} from "react-dnd";
import classnames from "classnames/bind";
import dayjs from "dayjs";
import isEqual from "lodash/isEqual";
import {Link} from "react-router-dom";

import {EmptyComponent} from "../../types";
import {
	Category,
	Collection,
	DELETE_POST,
	MovePostVars,
	REMOVE_CATEGORY_FROM_COLLECTION,
	REORDER_CATEGORIES,
	UPDATE_POST,
	Post,
	PostKeyword,
	postServices,
	PostServices,
	useMyUser,
	OPENGRAPH_FROM_URL,
	OpenGraph,
	services,
	UPLOAD_IMAGE,
} from "../../data";
import {
	Button,
	DropdownButton,
	EmojiPickerInput,
	InputHeader,
	Separator,
	Text,
	useValidate,
} from "../../components/input";
import {Arrow, Icon} from "../../components/images";
import {IconToggleGroup} from "../../components/toggle";
import {useMutationToast, useToast} from "../../toast";
import {
	useConfirmRevokeModal,
	ReferralCampaignModal,
	useNewModal as useModal,
	useConfirmDeleteModal,
	useConfirmModal,
} from "../../modals";
import {useKeywordsModal} from "../../modals/collections/keywords";
import {useDirtyCopy} from "../../dirty-copy";
import {ShuffleContentModal} from "../../modals/shuffle-content/shuffle-content";
import {useConfirmChangeModal} from "./change-modal";
import {Badge} from "../../components/badge";
import {Span2, Span3} from "../../components/text";
import {useDatePickerModal} from "../../modals/schedule";
import {ADD_CATEGORY_TO_COLLECTION, getPostEmv, PostServiceType} from "../../data/collection";
import {Media} from "../../data/media";
import {OpengraphMedia} from "../../components/opengraph";
import {MentionsText} from "../../components/input/mentions-text";
import {isURL, renderMentions} from "../../utils/text";
import {useStateRef} from "../../state-ref";
import {Loading} from "../../components/loading";
import {Pill} from "../../components/pill";
import {AITextPicker} from "../../components/input/ai-text-picker";
import {DELETE_SHUFFLED_COMMENTS} from "../../data/social-shuffle";
import {DirtyFields} from "../../modals/shuffle-content/shuffle-caption-tab";
import {networkMaxCharacters, networkMaxImages} from "./index";
import {MediaButton} from "../../components/media/media-button";
import {useCanvaDesign} from "../../hooks/use-canva-design";

import styles from "./post.module.scss";

const bStyles = classnames.bind(styles);

const PostDragType = "post";
const CategoryDragType = "category";

interface PostDragObject {
	id: number;
}

export interface PostListProps {
	availableCategories: Category[];
	collectionCategories?: number[];
	categoryId?: number;
	collectionId: number;
	originalPosts: number[];
	posts: Post[];
	onDelete: (idx: number) => void;
	movePost: (variables: MovePostVars) => void;
	disabled: boolean;
}

interface PostItemProps extends Omit<PostListProps, "posts"> {
	post: Post;
	idx: number;
	afterId?: number;
	startOpen?: boolean;
}

interface PostCategoryProps extends EmptyComponent {
	collection: Collection;
	availableCategories: Category[];
	category: Category;
	index: number;
	movePost: (variables: MovePostVars) => void;
	onMove: (id?: number, idx?: number) => void;
	onDelete: (idx: number) => void;
	disabled: boolean;
}

interface PostCategoryListProps extends EmptyComponent {
	categories: Category[];
	collection: Collection;
	onDelete: (idx: number) => void;
	movePost: (variables: MovePostVars) => void;
	disabled: boolean;
}

const iconMapper = {general: "email"};
const toggleOptions = postServices.map(s => ({value: s, icon: iconMapper[s] || s}));

export const PostCategoryList: FC<PostCategoryListProps> = ({
	categories,
	collection,
	movePost,
	onDelete,
	disabled,
}) => {
	const [reorderCategories] = useMutationToast(REORDER_CATEGORIES);
	const [order, setOrder] = useState<number[]>();
	const handleMove = useCallback(
		(id?: number, idx?: number) => {
			if (id === undefined) {
				if (order !== undefined)
					reorderCategories({variables: {id: collection.id, categoryIds: order}}).then(() =>
						setOrder(undefined)
					);
				return;
			}
			setOrder(c => {
				if (idx === undefined) return undefined;
				const ret = (c ?? collection.categories ?? []).filter(i => i !== id);
				ret.splice(idx, 0, id);
				return ret;
			});
		},
		[collection, order, reorderCategories]
	);
	return (
		<div>
			{(order ?? collection.categories ?? []).map((cat, i) => {
				const category = categories.find(c => c.id === cat);
				if (!category) return <></>;
				return (
					<PostCategory
						key={cat}
						index={i}
						category={category}
						availableCategories={categories}
						collection={collection}
						onMove={handleMove}
						onDelete={onDelete}
						movePost={movePost}
						disabled={disabled}
					/>
				);
			})}
		</div>
	);
};

export const PostCategory = ({
	category,
	availableCategories,
	collection,
	index,
	movePost,
	onDelete,
	onMove,
	disabled,
}: PostCategoryProps): ReactElement => {
	const [removeCategory] = useMutationToast(REMOVE_CATEGORY_FROM_COLLECTION);
	const handleRemove = useCallback(
		() => !disabled && removeCategory({variables: {id: collection.id, categoryId: category.id}}),
		[category.id, collection, removeCategory, disabled]
	);
	const [style, drag, preview] = useDrag(
		() => ({
			type: CategoryDragType,
			item: {id: category.id},
			collect: monitor => ({opacity: monitor.isDragging() ? 0 : 1}),
			end: (item, monitor) => {
				if (!monitor.didDrop()) onMove(category.id);
			},
		}),
		[category.id, onMove]
	);
	const [, drop] = useDrop(
		() => ({
			accept: CategoryDragType,
			drop: () => onMove(),
			hover({id}: {id: number}) {
				if (id !== category.id) onMove(id, index);
			},
		}),
		[category.id, index, onMove]
	);
	return (
		<div ref={node => preview(drop(node))} className="space" style={style}>
			<div className={bStyles("category", "space", disabled && "disabled")}>
				<Icon
					icon="drag"
					color="grey"
					height={16}
					className={[styles.draggable, disabled && styles.disabled]}
					ref={disabled ? null : drag}
				/>
				<Span2>{category.name}</Span2>
				<Icon icon="close" color="blue" className={disabled && styles.disabled} onClick={handleRemove} />
			</div>
			<PostList
				availableCategories={availableCategories}
				collectionCategories={collection.categories}
				disabled={disabled}
				categoryId={category.id}
				originalPosts={collection.posts.map(p => p.id).sort((a, b) => a - b)}
				posts={collection.posts.filter(p => p.categoryId === category.id)}
				onDelete={onDelete}
				collectionId={collection.id}
				movePost={movePost}
			/>
		</div>
	);
};

const emptyPostPlaceholder = "Write a comment or paste a link to share";

export const PostList = ({
	categoryId,
	collectionId: id,
	originalPosts,
	movePost,
	posts,
	disabled,
	...props
}: PostListProps): ReactElement => {
	const [over, drop] = useDrop<PostDragObject, unknown, boolean>(
		() => ({
			accept: PostDragType,
			drop: item => movePost({id, postId: item.id, categoryId}),
			collect: monitor => monitor.canDrop() && monitor.isOver(),
			canDrop: item => item.id !== posts[0]?.id,
		}),
		[categoryId, id, movePost, posts]
	);
	const [initialPostIds, setInitialPostIds] = useState(originalPosts);
	const onMove = useCallback(
		item => {
			setInitialPostIds(originalPosts);
			movePost(item);
		},
		[movePost, originalPosts]
	);

	return (
		<div>
			<div ref={disabled ? null : drop} className={styles.dropZone}>
				{over && <div className={styles.over} />}
			</div>
			{posts.map((p, i) => (
				<PostItem
					{...props}
					originalPosts={originalPosts}
					disabled={disabled}
					post={p}
					key={p.id}
					idx={i}
					collectionId={id}
					movePost={onMove}
					afterId={i < posts.length - 1 ? posts[i + 1].id : undefined}
					startOpen={!initialPostIds?.includes(p.id)}
					categoryId={categoryId}
				/>
			))}
		</div>
	);
};

const removeTypename = (obj: OpenGraph & {__typename?: string}) => {
	const cpy = {...obj};
	delete cpy.__typename;
	return cpy;
};

const PostItem = ({
	afterId,
	collectionId: id,
	idx,
	movePost,
	onDelete,
	post,
	disabled,
	startOpen,
	collectionCategories,
	availableCategories,
	categoryId,
}: PostItemProps): ReactElement => {
	const me = useMyUser();
	const isAIEnabled = me.org.options.ai;
	const [isLoading, setIsLoading] = useState(false);
	const [deletePost, {loading: deleting}] = useMutationToast(DELETE_POST);
	const [deleteShuffledComments, {loading: deletingShuffledComments}] = useMutationToast(
		DELETE_SHUFFLED_COMMENTS
	);
	const [uploadImage, {loading}] = useMutationToast(UPLOAD_IMAGE);
	const [perNetwork, setPerNetwork, perNetworkRef] = useStateRef(
		Object.values({...post.opengraphs, general: null, __typename: null}).filter(e => e !== null).length > 0
	);
	const [network, setNetwork, networkRef] = useStateRef<PostServices>(services[0]);
	const activeNetwork = perNetwork ? network : "general";
	const [updatePost, {loading: updatingPost}] = useMutationToast(UPDATE_POST);
	const [opengraphFromURL, {loading: loadingURL}] = useMutationToast(OPENGRAPH_FROM_URL);
	const [addCategory] = useMutationToast(ADD_CATEGORY_TO_COLLECTION);
	const onUpdate = useCallback(
		({val, discard, changes, ref}) => {
			const opengraphDeletions = {};
			const localOpengraphs = Object.keys(changes?.opengraphs ?? {}).reduce((acc, service) => {
				if (Object.keys(changes?.opengraphs?.[service]).length !== 0) {
					acc[service] = changes?.opengraphs?.[service];
					return acc;
				}
				if (Object.keys(val?.opengraphs?.[service] ?? {}).length !== 0) {
					opengraphDeletions[service] = true;
				}
				return acc;
			}, {});

			if (
				!changes &&
				Object.keys(localOpengraphs).length === 0 &&
				Object.keys(opengraphDeletions).length === 0
			)
				return Promise.resolve("No changes");

			if (postServices.some(s => (localOpengraphs[s]?.comment?.length ?? 0) > networkMaxCharacters[s]))
				return Promise.resolve("Invalid form");

			return updatePost({
				variables: {
					id,
					postId: val.id,
					changes: {
						...changes,
						...(changes?.keywords ? {keywords: changes.keywords.map(k => k.id)} : {}),
						opengraphs: localOpengraphs,
					},
					deletions: {
						expiresAt: changes && "expiresAt" in changes && changes.expiresAt === undefined,
						opengraphs: opengraphDeletions,
					},
				},
				onCompleted: () => {
					if (!isEqual(ref.current, changes)) return;
					discard();
					setIsLoading(false);
				},
			});
		},
		[id, updatePost]
	);

	const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(null);
	const textareaRef = React.useRef<HTMLTextAreaElement>(null);
	const {val, update, flush} = useDirtyCopy(post, {debounce: 2000, onUpdate});
	const updateActive = useCallback(
		(ogUpdate: Partial<OpenGraph>, baseUpdate: Partial<Post> = {}) => {
			const activeNetwork = perNetworkRef.current ? networkRef.current : "general";
			update((cur, changes) => ({
				...baseUpdate,
				opengraphs: {
					...(changes?.opengraphs ?? {}),
					[activeNetwork]: {
						...((ogUpdate.image || ogUpdate.video) &&
						["Text Share", "Image Share", "Video Share", ""].includes(
							cur.opengraphs[activeNetwork]?.title ?? ""
						)
							? {title: `${ogUpdate.video ? "Video" : "Image"} Share`}
							: {}),
						...(cur.opengraphs[activeNetwork] || activeNetwork === "general"
							? {}
							: removeTypename(cur.opengraphs.general)),
						...changes?.opengraphs?.[activeNetwork],
						...ogUpdate,
					},
				},
			}));
		},
		[networkRef, perNetworkRef, update]
	);

	const opengraph = useMemo(() => val.opengraphs[activeNetwork] ?? val.opengraphs.general ?? {}, [
		val.opengraphs,
		activeNetwork,
	]);
	const postType = useMemo(() => {
		if (val.url) return "url";

		const opengraphTypes = Object.keys(val.opengraphs).filter(s => s !== "__typename" && !!val.opengraphs[s]);
		const hasOpengraphMedia = opengraphTypes.some(
			type => !!val.opengraphs[type].image || !!val.opengraphs[type].video
		);
		if (hasOpengraphMedia) return "media";

		return "text";
	}, [val]);
	const isEMVEnabled = me.org.options.emvQueueEnabled;
	const toast = useToast();
	const handleMediaUpdate = useCallback(
		(selectedMedia: Media[]) => {
			if (val.url || opengraph.video || selectedMedia[0]?.type === "video") {
				updateActive({
					image: selectedMedia[0]?.type === "image" ? selectedMedia[0]?.url : selectedMedia[0]?.thumbnailUrl,
					video: selectedMedia[0]?.type === "video" ? selectedMedia[0]?.url : undefined,
				});
				return;
			}

			const newImages = selectedMedia.filter(m => m.type === "image").map(m => m.url);

			if (!newImages.length) return;

			const allImages = [
				...(opengraph.image ? [opengraph.image] : []),
				...(opengraph.images ?? []),
				...newImages,
			].slice(0, networkMaxImages[network || "general"]);

			updateActive({image: allImages[0]});
			updateActive({
				images: allImages.slice(1),
			});
		},
		[updateActive, val, opengraph, network]
	);
	const multiImage = useMemo(
		() => postType === "media" && opengraph.image && !opengraph.video && !post.shuffledImages?.length,
		[postType, opengraph.image, opengraph.video, post.shuffledImages]
	);
	const hasUrl = useMemo(() => !!val.url, [val.url]);
	const tabs = useMemo<("image" | "video")[]>(() => (hasUrl || multiImage ? ["image"] : ["image", "video"]), [
		hasUrl,
		multiImage,
	]);

	const handleKeywordsUpdate = useCallback(
		(keywords: PostKeyword[]) =>
			update({
				keywords: keywords.map(k => ({
					id: k.id,
					value: k.value,
					keyword: k.keyword,
					global: k.global,
				})),
			}),
		[update]
	);
	const handleReferralCampaignUpdate = useCallback((url: string) => update({url}), [update]);
	const {modal, open: openReferral} = useModal({});
	const keywordsModal = useKeywordsModal({
		onConfirm: handleKeywordsUpdate,
		selectedKeys: val.keywords,
	});

	const [dirtyFields, setDirtyFields] = useState<DirtyFields>({});
	const shuffleContentModal = useModal({
		size: "large",
		onConfirmCloseModal: Object.values(dirtyFields).some(Boolean)
			? () => {
					setDirtyFields({});
			  }
			: undefined,
	});

	const handleOpenShuffleContent = useCallback(() => {
		shuffleContentModal.open();
	}, [shuffleContentModal]);

	const [open, setOpen] = useState(!!startOpen);
	const [over, drop] = useDrop<PostDragObject, unknown, boolean>(
		() => ({
			accept: PostDragType,
			drop: ({id: postId}) => movePost({id, postId, afterPostId: post.id}),
			collect: monitor => monitor.canDrop() && monitor.isOver(),
			canDrop: ({id}) => id !== post.id && id !== afterId,
		}),
		[afterId, id, movePost, post.id]
	);
	const [isDragging, drag, preview] = useDrag<PostDragObject>(
		() => ({
			type: PostDragType,
			item: {id: post.id},
			collect: monitor => monitor.isDragging(),
		}),
		[post.id]
	);

	useEffect(() => {
		if (!open || disabled) return;

		textareaRef?.current?.focus();
	}, [disabled, open]);

	const onFileChange = useCallback(
		async (files: File[]) => {
			if (!files) return;

			const imgFiles = files.filter(file => file.type.split("/")?.[0] === "image");

			if (!imgFiles.length) {
				return;
			}

			const existingImages = [...(opengraph.image ? [opengraph.image] : []), ...(opengraph.images ?? [])];

			const results = await Promise.all(
				imgFiles
					.slice(0, networkMaxImages[network || "general"] - existingImages.length)
					.map(file => uploadImage({variables: {file}}))
			).catch(errors => toast({color: "red", text: errors[0], icon: "warning"}));

			if (!results) return;

			const newImages = results.map(({data}) => data?.uploadImage);

			if (val.url) {
				updateActive({image: newImages[0]});
				updateActive({images: undefined});
				return;
			}

			const allImages = [...existingImages, ...newImages].slice(0, networkMaxImages[network || "general"]);

			updateActive({image: allImages[0]});
			updateActive({images: allImages.slice(1)});
		},
		[toast, updateActive, uploadImage, opengraph, network, val.url]
	);

	const onCanvaFileSelect = useCallback(
		(url: string) => {
			if (!opengraph.image || !multiImage) {
				updateActive({image: url});
			} else {
				updateActive({images: [...(opengraph.images ?? []), url]});
			}
		},
		[updateActive, multiImage, opengraph]
	);

	const {loading: loadingCanva, createDesignJob} = useCanvaDesign({
		onCompleted: onCanvaFileSelect,
	});
	const onCanvaItemSelect = useCallback(
		designId => {
			createDesignJob(designId);
		},
		[createDesignJob]
	);

	const handleDelete = (close: () => void) =>
		deletePost({
			variables: {id: post.id, collectionId: id},
			onCompleted: () => {
				close();
				onDelete(idx);
			},
		});

	const handleEmojiChange = (emoji: string) => {
		const pos = textareaRef?.current?.selectionStart ?? 0;

		updateActive({
			comment: opengraph.comment
				? `${opengraph.comment?.slice(0, pos)}${emoji}${opengraph.comment?.slice(pos)}`
				: emoji,
		});
		const focusInput = () => {
			textareaRef?.current?.focus();
			textareaRef?.current?.setSelectionRange(pos + emoji.length, pos + emoji.length);
		};

		setTimeout(focusInput, 0);
	};

	const {open: confirmRevoke} = useConfirmRevokeModal({
		what: "post",
		onConfirm: handleDelete,
		confirming: deleting,
	});

	const {open: confirmDelete} = useConfirmDeleteModal({
		what: "post",
		onDelete: handleDelete,
		deleting: deleting,
	});

	const getEmvValue = useMemo(
		() =>
			new Intl.NumberFormat("en-US", {
				style: "currency",
				currency: "USD",
			}).format(getPostEmv(val)),
		[val]
	);

	const {open: addExpiration} = useDatePickerModal({
		type: "Post",
		value: val.expiresAt ?? dayjs(),
		min: dayjs(),
		onConfirm: (value, close) => {
			update({expiresAt: value});
			close();
		},
	});

	const handleChange = useCallback(
		(v: string) => {
			if (postType === "text" && isURL(v)) {
				const url = v.trim();
				setIsLoading(true);
				opengraphFromURL({variables: {url}}).then(({data, errors}) => {
					if (!data?.opengraphFromURL || errors) return;
					const opengraph = removeTypename({...data.opengraphFromURL});
					// updateActive(opengraph, {url});
					update((cur, changes) => ({
						...changes,
						url,
						opengraphs: Object.keys(cur.opengraphs)
							.filter(s => s !== "__typename" && !!cur.opengraphs[s])
							.reduce((acc, service) => {
								acc[service] = removeTypename({...cur.opengraphs[service], ...opengraph});
								return acc;
							}, {}),
					}));
					setTimeout(() => flush(), 0);
				});
			} else {
				updateActive({comment: v});
			}
		},
		[postType, opengraphFromURL, update, flush, updateActive]
	);

	const resetToGeneral = useCallback(
		close => {
			close();
			setPerNetwork(false);
			if (services.filter(s => Object.keys(val.opengraphs[s] ?? {}).length !== 0).length !== 0) {
				update(() => ({
					opengraphs: {
						facebook: {},
						twitter: {},
						linkedin: {},
						instagram: {},
					},
				}));
			}
		},
		[setPerNetwork, val, update]
	);

	const resetAndRemove = useCallback(
		close => {
			setPerNetwork(false);
			update({
				opengraphs: {
					general: {
						title: "",
						image: null,
						description: "",
					},
					facebook: {},
					twitter: {},
					linkedin: {},
					instagram: {},
				},
				url: null,
			});
			close();
		},
		[setPerNetwork, update]
	);

	const resetShuffleContent = useCallback(
		async close => {
			setPerNetwork(true);
			flush();
			await deleteShuffledComments({variables: {queuedUrlId: post?.id}});
			close();
		},
		[setPerNetwork, flush, deleteShuffledComments, post?.id]
	);

	const confirmDeleteModal = useConfirmChangeModal({
		confirmColor: "pink",
		confirmText: "Delete Changes",
		onConfirm: resetToGeneral,
	});

	const confirmResetShuffleModal = useConfirmChangeModal({
		confirmColor: "pink",
		confirmText: "Delete Shuffle Content",
		onConfirm: resetShuffleContent,
		confirming: deletingShuffledComments,
	});

	const confirmResetAndRemove = useConfirmChangeModal({
		confirmColor: "pink",
		confirmText: "Remove URL",
		confirming: updatingPost,
		onConfirm: resetAndRemove,
	});

	const socialShuffleCommentsNumber = post?.shuffledCommentsCount ?? 0;
	const toggleCustomizePerNetwork = useCallback(
		close => {
			close();
			if (perNetwork) {
				confirmDeleteModal.open();
			} else {
				if (socialShuffleCommentsNumber > 0) {
					confirmResetShuffleModal.open();
				} else {
					setPerNetwork(c => !c);
				}
			}
		},
		[confirmDeleteModal, confirmResetShuffleModal, socialShuffleCommentsNumber, perNetwork, setPerNetwork]
	);

	const removeURL = useCallback(() => {
		update({
			opengraphs: {
				general: {
					title: "",
					image: null,
					video: null,
					description: "",
				},
			},
			url: null,
		});
	}, [update]);

	const onRemoveAll = useCallback(() => {
		if (postType === "url") {
			if (perNetwork) {
				confirmResetAndRemove.open();
				return;
			}
			removeURL();
		} else {
			updateActive({image: null, video: null, title: "", description: ""});
		}
	}, [removeURL, perNetwork, confirmResetAndRemove, postType, updateActive]);

	const handleTitleChange = useCallback(title => updateActive({title}), [updateActive]);
	const handleDescriptionChange = useCallback(description => updateActive({description}), [updateActive]);

	const hasOpengraphMedia = useMemo(() => !!(opengraph.image || opengraph.video), [
		opengraph.image,
		opengraph.video,
	]);

	const hasVideoTitle = useMemo(
		() =>
			!!(val.opengraphs[activeNetwork]?.video || val.opengraphs["general"]?.video) &&
			["general", "linkedin"].includes(activeNetwork),
		[val.opengraphs, activeNetwork]
	);
	const hasTitle = useMemo(() => hasUrl || hasVideoTitle, [hasUrl, hasVideoTitle]);
	const hasOpengraphText = useMemo(() => hasTitle && ["facebook", "general"].includes(activeNetwork), [
		hasTitle,
		activeNetwork,
	]);
	const {error, inputProps} = useValidate(String(post?.id), opengraph.comment, [
		{
			immediate: true,
			check: v => ((v?.length ?? 0) > networkMaxCharacters[activeNetwork] ? "Caption is too long" : false),
		},
		{
			immediate: !startOpen,
			check: v =>
				!(hasOpengraphText || hasOpengraphMedia || post.url) && !v ? "You must enter a caption." : false,
		},
	]);
	useValidate(`${id}-${post.id}`, val, {
		immediate: true,
		check: v =>
			Object.keys(v.opengraphs).some(s => (v.opengraphs[s]?.comment?.length ?? 0) > networkMaxCharacters[s])
				? "Caption too long"
				: false,
	});

	const handleRemoveMultiImage = useCallback(() => {
		const images = [opengraph.image, ...(opengraph.images ?? [])].filter(
			(img, i) => img && i !== selectedImageIndex
		) as string[];
		updateActive({image: images[0] || null, images: images.slice(1)});
	}, [updateActive, opengraph, selectedImageIndex]);

	const {open: openConfirmRemoveImage} = useConfirmModal(
		() => ({
			title: "Remove selected image?",
			body: <div>Are you sure you want to delete the selected image?</div>,
			confirmText: "Confirm",
			onConfirm: close => {
				handleRemoveMultiImage();
				close();
			},
		}),
		[handleRemoveMultiImage]
	);

	const onRemoveMultiImage = useCallback(
		(index: number) => {
			setSelectedImageIndex(index);
			openConfirmRemoveImage();
		},
		[setSelectedImageIndex, openConfirmRemoveImage]
	);

	const opengraphImages = useMemo(
		() =>
			[opengraph.image, ...(opengraph.images ?? [])].slice(0, networkMaxImages[activeNetwork || "general"]),
		[opengraph.image, opengraph.images, activeNetwork]
	);

	return (
		<>
			<div className={bStyles(styles.postItem, {postError: !!error})}>
				<div ref={node => preview(node)} className={bStyles("postHeader", {isDragging})}>
					<Icon
						icon="drag"
						height={16}
						color="grey"
						ref={disabled ? null : drag}
						className={[styles.draggable, disabled && styles.disabled]}
					/>
					<div className={styles.content}>
						{perNetwork && open && (
							<IconToggleGroup value={network} options={toggleOptions} onChange={setNetwork} />
						)}
						<div className={bStyles("previewContainer", "space")}>
							{!open && <OpengraphMedia openGraph={opengraph} height={40} width={80} />}
							{disabled ||
								(!open && (
									<Span3 trim={1}>
										{opengraph.comment?.length ? renderMentions(opengraph.comment) : emptyPostPlaceholder}
									</Span3>
								))}
						</div>
						{perNetwork && val.url && open && (
							<Pill
								outline
								disabled={disabled}
								textClassName={styles.postUrl}
								color="blue"
								text={val.url}
								onDelete={confirmResetAndRemove.open}
							/>
						)}
					</div>
					<Arrow direction={open ? "up" : "down"} onClick={() => setOpen(c => !c)} />
				</div>
				{open && (
					<div className={styles.postBody}>
						<MentionsText
							ref={textareaRef}
							type="textarea"
							placeholder={emptyPostPlaceholder}
							onChange={handleChange}
							value={opengraph.comment ?? ""}
							bare
							disabled={disabled}
							loading={loadingURL || isLoading}
							service={PostServiceType[activeNetwork || "general"]}
							characterCount
							maxLength={perNetwork ? networkMaxCharacters[activeNetwork] : networkMaxCharacters.general}
							{...inputProps}
						/>
						{(hasOpengraphText || hasOpengraphMedia || val.url || loadingURL || loadingCanva) && (
							<div>
								{multiImage ? (
									<div className={styles.multiImage}>
										<div className={styles.imagesCt}>
											{opengraphImages.map((image, index) => (
												<div key={index} className={styles.multiImageItem}>
													<OpengraphMedia openGraph={{image}} width={96} height={96} />
													<div className={styles.removeBtn} onClick={() => onRemoveMultiImage(index)}>
														<Icon icon="close" width={24} color="white" />
													</div>
												</div>
											))}
											<MediaButton
												ButtonComponent={({isOpen}) => (
													<div
														className={bStyles(styles.addImageBtn, {
															[styles.active]: isOpen,
															[styles.disabled]:
																opengraphImages.length >= networkMaxImages[activeNetwork || "general"],
														})}
													>
														<div className={styles.icon}>
															<Icon icon="add" color="white" width={16} height={16} viewBox={"0 0 24 24"} />
														</div>
														{(loading || loadingCanva) && <Loading size="small" className={styles.loading} />}
													</div>
												)}
												disabled={opengraphImages.length >= networkMaxImages[activeNetwork || "general"]}
												dropdownPlacement={"right-start"}
												onFileChange={onFileChange}
												onCanvaSelect={onCanvaItemSelect}
												multiple={true}
												mediaLibrary={{
													onConfirm: handleMediaUpdate,
													tabs: ["image"],
													multiple: true,
												}}
											/>
										</div>
										<div className={styles.multiImageInfo}>
											<Icon icon="information" width={20} height={20} color="blue" />
											<Span3 trim={2}>
												Certain social networks limit the number of images that can be added to a post.
											</Span3>
											<Span3 color="blue" bold href="https://cvssupport.wpenginepowered.com/">
												Learn more
											</Span3>
										</div>
									</div>
								) : (
									<div
										className={bStyles("imageAndDescriptionContainer", {
											open,
											loadingOgMedia: loadingURL,
										})}
									>
										{loadingURL || loadingCanva ? (
											<Loading position="center" />
										) : (
											<OpengraphMedia
												className={bStyles("opengraphMedia", {ogMediaCut: hasTitle})}
												openGraph={opengraph}
												showControls
											/>
										)}
										{!loadingURL && postType !== "text" && !perNetwork && (
											<Icon icon="close" className={styles.closeIcon} onClick={onRemoveAll} />
										)}
										{hasTitle && (
											<div className={styles.ogMetadataContainer}>
												{val.url && (
													<Link className={styles.ogMetadataUrl} to={val.url} target="_blank">
														{val.url}
													</Link>
												)}
												{!loadingURL && (
													<div className={styles.title}>
														<Text
															bold
															value={opengraph.title ?? ""}
															placeholder="Add a title"
															disabled={disabled}
															onChange={handleTitleChange}
															bare
															characterCount={activeNetwork === "linkedin"}
															maxLength={perNetwork ? (activeNetwork === "linkedin" ? 70 : 100) : undefined}
															type="textarea"
															minRows={1}
														/>
														{isAIEnabled && !disabled && (
															<AITextPicker
																value={opengraph.title || ""}
																label={"Title"}
																onChange={handleTitleChange}
																disabled={disabled}
																maxLength={100}
																className={styles.aiTextPicker}
															/>
														)}
													</div>
												)}
												{hasOpengraphText && (
													<div className={styles.description}>
														<Text
															bare
															className={styles.description}
															value={opengraph.description ?? ""}
															type="textarea"
															minRows={3}
															placeholder="Add a description."
															disabled={disabled}
															onChange={handleDescriptionChange}
														/>
														{isAIEnabled && !disabled && (
															<AITextPicker
																value={opengraph.description || ""}
																label={"Description"}
																onChange={handleDescriptionChange}
																disabled={disabled}
																maxLength={300}
																className={styles.aiTextPicker}
															/>
														)}
													</div>
												)}
											</div>
										)}
									</div>
								)}
							</div>
						)}
						<InputHeader>
							<>
								{!disabled && (
									<>
										<MediaButton
											onFileChange={onFileChange}
											loading={loading || loadingCanva}
											onCanvaSelect={onCanvaItemSelect}
											multiple={!val.url && !opengraph.video}
											mediaLibrary={{
												onConfirm: handleMediaUpdate,
												tabs: tabs,
												multiple: !val.url && !opengraph.video,
											}}
										/>

										<EmojiPickerInput value="" onChange={handleEmojiChange} disabled={disabled} />

										{isAIEnabled && (
											<AITextPicker
												value={
													renderMentions(opengraph.comment) || opengraph.title || opengraph.description || ""
												}
												label={"Comment"}
												onChange={handleChange}
												disabled={disabled}
											/>
										)}
										<Separator />
										<DropdownButton
											options={[
												{label: "Add Expiration", onClick: addExpiration},
												{
													label: "Remove Expiration Time",
													onClick: () => update({expiresAt: undefined}),
													hidden: !val.expiresAt,
												},
												{
													label: "Referral & Campaign Tracking",
													onClick: openReferral,
													hidden: !val.url,
												},
												{
													label: "Customize Per Network",
													onClick: toggleCustomizePerNetwork,
													icon: "switch",
													selected: perNetwork,
												},
											]}
											value="More"
											invert
											arrow
											border={false}
											disabled={disabled}
										/>
										{categoryId && (
											<DropdownButton
												options={availableCategories.map(c => ({
													label: c.name,
													onClick: () => {
														if (collectionCategories?.includes(c.id)) {
															movePost({id, postId: post.id, categoryId: c.id});
														} else {
															addCategory({
																variables: {
																	id: id,
																	categoryId: c.id,
																},
															}).then(() => {
																movePost({id, postId: post.id, categoryId: c.id});
															});
														}
													},
												}))}
												value={availableCategories.find(c => c.id === categoryId)?.name}
												invert
												arrow
												searchBar
												border={false}
											/>
										)}
									</>
								)}

								{val.expiresAt && (
									<Span3 color="grey">Expires {val.expiresAt.formatAs("longDateAndTime")}</Span3>
								)}
							</>
							<>
								<Button
									color="blue"
									invert
									border={false}
									icon="shuffle"
									value={disabled ? "View Shuffle Content" : "Shuffle Content"}
									onClick={handleOpenShuffleContent}
									disabled={perNetwork}
								/>
								{socialShuffleCommentsNumber > 0 && (
									<Badge text={socialShuffleCommentsNumber + 1} color="blue" size="small" />
								)}
							</>
						</InputHeader>
						<Separator horizontal />
						<InputHeader>
							<>
								{val.url && (
									<Button
										color="black"
										invert
										disabled={!isEMVEnabled || disabled}
										border={false}
										icon="information"
										value={`EMV: ${getEmvValue}`}
										onClick={keywordsModal.open}
									/>
								)}
							</>
							<Button
								color="black"
								invert
								border={false}
								icon="delete"
								value={disabled ? "Revoke" : "Delete"}
								onClick={disabled ? confirmRevoke : confirmDelete}
							/>
						</InputHeader>
					</div>
				)}
			</div>
			<div ref={!disabled ? drop : null} className={styles.dropZone}>
				{over && <div className={styles.over} />}
			</div>
			<ReferralCampaignModal url={val.url ?? ""} modal={modal} onConfirm={handleReferralCampaignUpdate} />
			<ShuffleContentModal
				modal={shuffleContentModal.modal}
				collectionId={id}
				post={val}
				disabled={disabled}
				dirtyFields={dirtyFields}
				setDirtyFields={setDirtyFields}
				tabs={{
					caption: true,
					image: !(multiImage && opengraphImages.length > 1),
				}}
			/>
		</>
	);
};
