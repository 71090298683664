import React from "react";
import classNames from "classnames";

import {OpenGraph} from "../../../../data";
import {Icon} from "../../../../components/images";
import {Loading} from "../../../../components/loading";
import {useNewModal, NewModal as Modal} from "../../../../modals";

import styles from "./components.module.scss";

interface MediaProps {
	image?: OpenGraph["image"];
	video?: OpenGraph["video"];
	width?: number;
	loadingMedia?: boolean;
	height?: number;
	border?: boolean;
	onRemove?: () => void;
	disabled?: boolean;
}
export const Media = ({
	image,
	video,
	width = 128,
	height = 96,
	border,
	onRemove,
	loadingMedia,
	disabled,
}: MediaProps) => {
	const {open, modal} = useNewModal({size: "medium"});
	return (
		<div
			className={classNames(styles.media, (border || loadingMedia) && styles.border)}
			style={{width, height}}
		>
			{loadingMedia ? (
				<Loading position="absolute" size="small" />
			) : (
				<>
					{image && <img onClick={open} src={image} alt="Post Image" />}
					{video && !image && (
						<video src={video} controls={true} muted preload="metadata">
							<source src={video} />
							<track kind="captions" srcLang="en" label="english_captions" />
						</video>
					)}
					{onRemove && !disabled && (
						<div className={styles.removeBtn} onClick={onRemove}>
							<Icon icon="close" width={24} color="white" />
						</div>
					)}
				</>
			)}
			{image && (
				<Modal modal={modal}>
					<img className={styles.preview} src={image} alt="Post Image" />
				</Modal>
			)}
			{video && (
				<Modal modal={modal}>
					<video className={styles.preview} src={video} controls={true} muted preload="metadata">
						<source src={video} />
						<track kind="captions" srcLang="en" label="english_captions" />
					</video>
				</Modal>
			)}
		</div>
	);
};
