import React, {useCallback, useMemo, useState} from "react";
import dayjs from "dayjs";
import {useFormikContext} from "formik";
import {DefaultContext, FetchResult, MutationFunctionOptions, OperationVariables} from "@apollo/client";

import {DELETE_POST, PostServices, postServices, ShuffledComment, ShuffledImage} from "../../../../data";
import {IconToggleGroup} from "../../../../components/toggle";
import {Button, EmojiPickerInput, SmallButton} from "../../../../components/input";
import {AITextPicker} from "../../../../components/input/ai-text-picker";
import {useDatePickerModal} from "../../../../modals/schedule";
import {PostCollectionFormValues, OnChange} from "./post";
import {useMutationToast, useToast} from "../../../../toast";
import {
	ReferralCampaignModal,
	useConfirmModal,
	useConfirmDeleteModal,
	useNewModal as useModal,
} from "../../../../modals";
import {Icon} from "../../../../components/images";
import {Span3, Span4} from "../../../../components/text";
import {Media as MediaType} from "../../../../data/media";
import {MediaButton} from "../../../../components/media/media-button";
import {Setter} from "../../../../types";
import {useConfirmChangeModal} from "../../../collections/change-modal";
import {Media} from "../../components";
import {useMediaLibraryModal} from "../../../../modals/media/media-library";
import {HoverTooltip} from "../../../../components/tooltip";
import {networkMaxImages} from "../../components/post/multi-image";

import styles from "./styles.module.scss";

const iconMapper = {general: "email"};

interface NetworkToolbarProps {
	onChange: OnChange;
}

export const NetworkToolbar = ({onChange}: NetworkToolbarProps) => {
	const {values} = useFormikContext<PostCollectionFormValues>();
	const {activeNetwork} = values;

	const toggleOptions = postServices?.map(s => ({value: s, icon: iconMapper[s] || s}));
	const onNetworkChange = useCallback((network: PostServices) => onChange("activeNetwork", network), [
		onChange,
	]);

	return (
		<div className={styles.editorTopToolbar}>
			<IconToggleGroup
				className={styles.toggleNetwork}
				options={toggleOptions}
				value={activeNetwork}
				onChange={onNetworkChange}
			/>
		</div>
	);
};
interface ShuffleCommentsToolbarProps {
	shuffledCommentIndex: number;
	setShuffledCommentIndex: Setter<number>;
}
interface ShuffleCommentsToolbarComponentProps {
	shuffledCommentIndex: number;
	setShuffledCommentIndex: Setter<number>;
	shuffledComments: ShuffledComment[];
}

export const ShuffleCommentsToolbar = ({
	shuffledCommentIndex,
	setShuffledCommentIndex,
}: ShuffleCommentsToolbarProps) => {
	const {values} = useFormikContext<PostCollectionFormValues>();

	return (
		<ShuffleCommentsToolbarComponent
			shuffledCommentIndex={shuffledCommentIndex}
			setShuffledCommentIndex={setShuffledCommentIndex}
			shuffledComments={values.shuffledComments}
		/>
	);
};

export const ShuffleCommentsToolbarComponent = ({
	shuffledCommentIndex,
	setShuffledCommentIndex,
	shuffledComments,
}: ShuffleCommentsToolbarComponentProps) => {
	const shuffledCommentsCount = (shuffledComments?.length ?? 0) + 1;
	const onPrevClick = useCallback(() => {
		if (shuffledCommentIndex === 1) return;
		setShuffledCommentIndex(shuffledCommentIndex - 1);
	}, [setShuffledCommentIndex, shuffledCommentIndex]);
	const onNextClick = useCallback(() => {
		if (shuffledCommentIndex === shuffledCommentsCount) return;
		setShuffledCommentIndex(shuffledCommentIndex + 1);
	}, [setShuffledCommentIndex, shuffledCommentIndex, shuffledCommentsCount]);

	return (
		<div className={styles.editorTopToolbar}>
			<div className={styles.shuffledCommentsNav}>
				<div className={styles.navigator}>
					<div className={styles.navPrev} onClick={onPrevClick}>
						<Icon icon="chevron-up" color={shuffledCommentIndex === 1 ? "grey" : "black"} />
					</div>
					<Span3 color="black">{`Caption ${shuffledCommentIndex}/${shuffledCommentsCount}`}</Span3>
					<div className={styles.navNext} onClick={onNextClick}>
						<Icon
							icon="chevron-down"
							color={(shuffledCommentIndex ?? 1) < shuffledComments?.length + 1 ? "black" : "grey"}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

interface BottomToolbarProps {
	comment?: string;
	image?: string;
	images?: string[];
	onChange: OnChange;
	onOpengraphChange: OnChange;
	onDelete?: () => void;
	disabled?: boolean;
	multiImage?: boolean;
	uploadImage: (
		options?: MutationFunctionOptions<{uploadImage: string}, OperationVariables, DefaultContext>
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	) => Promise<FetchResult<any>>;
	createDesignJob: (designId: string) => void;
	optimizedForTwitter: boolean;
	setOptimizedForTwitter: Setter<boolean>;
	maxLength?: number;
	aiEnabled?: boolean;
}

export const BottomToolbar = ({
	comment,
	image,
	images,
	onChange,
	onOpengraphChange,
	onDelete,
	uploadImage,
	createDesignJob,
	disabled,
	optimizedForTwitter,
	setOptimizedForTwitter,
	maxLength,
	multiImage,
	aiEnabled,
}: BottomToolbarProps) => {
	const {values, isSubmitting, setFieldValue} = useFormikContext<PostCollectionFormValues>();
	const {
		id,
		url,
		collectionId,
		expiresAt,
		perNetwork,
		shuffledComments,
		shuffledImages,
		opengraphs,
		activeNetwork,
	} = values;
	const [deletePost, {loading: deleting}] = useMutationToast(DELETE_POST);
	const toast = useToast();
	const [uploading, setUploading] = useState(false);
	const tabs = useMemo<("image" | "video")[]>(() => (url || multiImage ? ["image"] : ["image", "video"]), [
		url,
		multiImage,
	]);
	const activeTitle = useMemo(() => opengraphs?.[activeNetwork]?.title, [activeNetwork, opengraphs]);
	const handleMediaUpdate = useCallback(
		(selectedMedia: MediaType[]) => {
			if (!selectedMedia[0]) return;

			if (selectedMedia[0]?.type === "video") {
				onOpengraphChange("image", selectedMedia[0].thumbnailUrl);
				onOpengraphChange("video", selectedMedia[0].url);
				if (activeTitle === "Text Share") {
					onOpengraphChange("title", null);
				}
				return;
			}

			const newImages = selectedMedia.filter(m => m.type === "image").map(m => m.url);

			if (!newImages.length) return;

			const allImages = [...(image ? [image] : []), ...(images ?? []), ...newImages].slice(
				0,
				networkMaxImages[activeNetwork || "general"]
			);

			onOpengraphChange("image", !url ? allImages[0] : newImages[0]);
			onOpengraphChange("images", !url ? allImages.slice(1) : undefined);
			onOpengraphChange("video", undefined);
		},
		[onOpengraphChange, image, images, activeNetwork, url, activeTitle]
	);

	const onCanvaItemSelect = useCallback(
		designId => {
			createDesignJob(designId);
		},
		[createDesignJob]
	);
	const onFileChange = useCallback(
		async (files: File[]) => {
			if (!files) return;

			const imgFiles = files.filter(file => file.type.split("/")?.[0] === "image");

			if (!imgFiles.length) {
				return;
			}

			setUploading(true);

			const existingImages = [...(image ? [image] : []), ...(images ?? [])];

			const results = await Promise.all(
				imgFiles
					.slice(0, networkMaxImages[activeNetwork || "general"] - existingImages.length)
					.map(file => uploadImage({variables: {file}}))
			).catch(errors => toast({color: "red", text: errors[0], icon: "warning"}));

			setUploading(false);

			if (!results) return;

			const newImages = results.map(({data}) => data?.uploadImage);

			const allImages = [...existingImages, ...newImages].slice(
				0,
				networkMaxImages[activeNetwork || "general"]
			);

			onOpengraphChange("image", !url ? allImages[0] : newImages[0]);
			onOpengraphChange("images", !url ? allImages.slice(1) : undefined);
			onOpengraphChange("video", undefined);
		},
		[toast, uploadImage, onOpengraphChange, image, images, activeNetwork, setUploading, url]
	);
	const handleDelete = useCallback(
		(close: () => void) =>
			deletePost({
				variables: {id, collectionId},
			}).then(() => {
				close();
				onDelete?.();
			}),
		[deletePost, id, collectionId, onDelete]
	);

	const {open: confirmDelete} = useConfirmDeleteModal({
		what: "post",
		onDelete: handleDelete,
		deleting,
	});

	const onDeleteClick = useCallback(() => {
		if (id) {
			return confirmDelete();
		}

		onDelete?.();
	}, [confirmDelete, id, onDelete]);

	const {open: addExpiration} = useDatePickerModal({
		type: "Post",
		value: expiresAt ?? dayjs(),
		min: dayjs(),
		showClear: true,
		onConfirm: (value, close) => {
			onChange("expiresAt", value ?? undefined);
			close();
		},
	});

	const {modal: referralModal, open: openReferral} = useModal({});
	const resetShuffleContent = useCallback(
		async close => {
			await onChange("shuffledComments", undefined);
			onChange("shuffledImages", undefined);
			close();
		},
		[onChange]
	);
	const confirmResetShuffleModal = useConfirmChangeModal({
		confirmColor: "pink",
		confirmText: "Delete Shuffle Content",
		onConfirm: resetShuffleContent,
	});
	const confirmRemoveMultiImagesModal = useConfirmModal(
		() => ({
			onConfirm: close => {
				onOpengraphChange("images", null);
				setFieldValue("shuffledImages", []);
				setFieldValue("shuffledComments", []);
				close();
			},
			confirmColor: "pink",
			confirmText: "Remove",
			title: "Remove selected media?",
			body: (
				<Span3>
					Are you sure you want to delete added images? If you remove them, you can only add again if you
					remove the shuffled content.
				</Span3>
			),
		}),
		[setFieldValue, onOpengraphChange]
	);
	const onShuffleClick = useCallback(async () => {
		if (!shuffledComments && !shuffledImages && opengraphs?.general?.images?.length) {
			return confirmRemoveMultiImagesModal.open();
		}

		if (!shuffledComments && !shuffledImages) {
			return Promise.all([setFieldValue("shuffledImages", []), setFieldValue("shuffledComments", [])]);
		}

		if (!shuffledComments?.length && !shuffledImages?.length) {
			await onChange("shuffledImages", undefined);
			onChange("shuffledComments", undefined);
			return;
		}

		return confirmResetShuffleModal.open();
	}, [
		confirmResetShuffleModal,
		confirmRemoveMultiImagesModal,
		shuffledImages,
		shuffledComments,
		setFieldValue,
		onChange,
		opengraphs,
	]);

	const isShuffleContentEnabled = (shuffledComments || shuffledImages) && !perNetwork;

	return (
		<div className={styles.editorBottomToolbar}>
			<div>
				<MediaButton
					onFileChange={onFileChange}
					onCanvaSelect={onCanvaItemSelect}
					multiple={!url && !isShuffleContentEnabled}
					loading={uploading}
					mediaLibrary={{
						onConfirm: handleMediaUpdate,
						tabs: tabs,
						multiple: !url && !isShuffleContentEnabled,
					}}
				/>
				<EmojiPickerInput value={""} onChange={v => onOpengraphChange("emoji", v)} disabled={disabled} />
				{aiEnabled && (
					<AITextPicker
						value={comment || ""}
						label="Comment"
						onChange={v => onOpengraphChange("comment", v)}
						disabled={disabled}
						maxLength={Math.min(maxLength ?? 300, 300)}
					/>
				)}
				<div className={styles.buttonsSeparator} />
				{!perNetwork && (
					<HoverTooltip text={"Optimize for X"} positions={["top"]}>
						<Button
							icon="twitter"
							value=""
							invert
							border={false}
							onClick={() => setOptimizedForTwitter(!optimizedForTwitter)}
							color={optimizedForTwitter ? "blue" : "black"}
							active={optimizedForTwitter}
							disabled={!id}
						/>
					</HoverTooltip>
				)}
				<HoverTooltip text={"Shuffle Content"} positions={["top"]}>
					<Button
						icon="shuffle"
						onClick={onShuffleClick}
						invert
						border={false}
						disabled={perNetwork || !id}
						color={isShuffleContentEnabled ? "blue" : "black"}
						active={isShuffleContentEnabled}
					/>
				</HoverTooltip>
				{url && (
					<HoverTooltip text={"Referral & Campaign Tracking"} positions={["top"]}>
						<Button icon="tracking" value="" onClick={openReferral} invert border={false} color="black" />
					</HoverTooltip>
				)}
				<div className={styles.expiration} onClick={addExpiration}>
					<HoverTooltip text={"Expiration"} positions={["top"]}>
						<Icon icon="schedule" />
					</HoverTooltip>
					{expiresAt ? (
						<Span3 color="pink">Expires: {expiresAt.formatAs("longDateAndTime")}</Span3>
					) : (
						<Span3>No expiration</Span3>
					)}
				</div>
			</div>
			<HoverTooltip text={"Remove Post"} positions={["top"]}>
				<Button
					onClick={onDeleteClick}
					icon="delete"
					value=""
					color="black"
					invert
					border={false}
					loading={deleting || isSubmitting}
				/>
			</HoverTooltip>
			<ReferralCampaignModal
				url={url ?? ""}
				modal={referralModal}
				onConfirm={(url: string) => onChange("url", url)}
			/>
		</div>
	);
};

interface CommentToolbarProps {
	onChange: OnChange;
	shuffledCommentIndex?: number;
	setShuffledCommentIndex: Setter<number>;
	canAddCaption: boolean;
	loadingAddShuffle: boolean;
}
export const CommentToolbar = ({
	onChange,
	shuffledCommentIndex,
	setShuffledCommentIndex,
	loadingAddShuffle,
	canAddCaption,
}: CommentToolbarProps) => {
	const {values} = useFormikContext<PostCollectionFormValues>();
	const {id, shuffledComments} = values;

	const onAddCaption = useCallback(async () => {
		onChange("shuffledComments", [...shuffledComments, {comment: "", queuedUrlId: id, tempId: Date.now()}]);
		setShuffledCommentIndex(shuffledComments.length + 2);
	}, [id, onChange, shuffledComments, setShuffledCommentIndex]);

	const onDeleteCaption = useCallback(async () => {
		if (shuffledCommentIndex && shuffledCommentIndex > 1) {
			let arrCpy = [...shuffledComments];
			if (arrCpy.length > 1) arrCpy?.splice(shuffledCommentIndex - 2, 1);
			else {
				arrCpy = [];
			}
			onChange("shuffledComments", arrCpy);
			setShuffledCommentIndex((shuffledCommentIndex || 1) - 1);
		}
	}, [shuffledCommentIndex, onChange, shuffledComments, setShuffledCommentIndex]);

	return (
		<div className={styles.editorCaptionToolbar}>
			<SmallButton
				onClick={onDeleteCaption}
				value="Delete Caption"
				invert
				border={false}
				color="pink"
				disabled={shuffledCommentIndex === 1 || loadingAddShuffle}
			/>
			<div className={styles.buttonsSeparator} />
			<SmallButton
				disabled={!canAddCaption}
				onClick={onAddCaption}
				value="Add Caption"
				invert
				border={false}
				color="blue"
			/>
		</div>
	);
};

interface MediaToolbarProps {
	onChange: OnChange;
}

export const MediaToolbar = ({onChange}: MediaToolbarProps) => {
	const {values, isSubmitting} = useFormikContext<PostCollectionFormValues>();
	const {id, opengraphs, shuffledImages} = values;
	const handleAddImage = useCallback(
		(media: MediaType[]) => {
			const images = media.filter(m => m.type === "image").map(m => m.url);

			if (!images.length) return;

			onChange("shuffledImages", [...shuffledImages, ...images.map(url => ({image: url, queuedUrlId: id}))]);
		},
		[id, onChange, shuffledImages]
	);
	const mediaLibraryModal = useMediaLibraryModal({
		onConfirm: handleAddImage,
	});
	const handleDelete = useCallback(
		(shuffledImage: ShuffledImage) => {
			onChange(
				"shuffledImages",
				shuffledImages.filter(
					si => (shuffledImage.id && shuffledImage.id !== si.id) || shuffledImage.image !== si.image
				)
			);
		},
		[onChange, shuffledImages]
	);

	return (
		<div className={styles.editorMediaToolbar}>
			<div className={styles.addShuffleImage} onClick={mediaLibraryModal.open}>
				<Icon icon="add" />
				<Span4 color="grey">Shuffle Image</Span4>
			</div>
			{[{image: opengraphs.general.image, id: ""} as ShuffledImage, ...shuffledImages].map((si, index) => (
				<Media
					key={`${index}-${si.image}`}
					image={si.image}
					width={96}
					height={72}
					onRemove={index && !isSubmitting ? () => handleDelete(si) : undefined}
				/>
			))}
		</div>
	);
};
