import React, {useCallback, useMemo, useState} from "react";
import dayjs, {Dayjs} from "dayjs";
import {useFormikContext} from "formik";
import {DefaultContext, FetchResult, MutationFunctionOptions, OperationVariables} from "@apollo/client";
import {useNavigate} from "react-router-dom";

import {FormValues, OnChange} from "./types";
import {
	accountNames,
	DELETE_SHARE_EVENT,
	GENERATE_PEAK_TIME,
	PostServices,
	postServices,
	ShareEvent,
} from "../../../../data";
import {IconToggleGroup} from "../../../../components/toggle";
import {Button, EmojiPickerInput} from "../../../../components/input";
import {AITextPicker} from "../../../../components/input/ai-text-picker";
import {Span2} from "../../../../components/text";
import {MediaButton} from "../../../../components/media/media-button";
import {useMutationToast, useToast} from "../../../../toast";
import {Media} from "../../../../data/media";
import {useConfirmDeleteModal} from "../../../../modals";
import {HoverTooltip} from "../../../../components/tooltip";
import {PeakTimeCalendar} from "../../../../components/input/date-time/date-time-picker";
import {networkMaxImages} from "../../components/post/multi-image";

import styles from "./styles.module.scss";

const iconMapper = {general: "email"};

interface TopToolbarProps {
	activeNetwork: PostServices;
	availableNetworks: PostServices[];
	onChange: OnChange;
}
interface BottomToolbarProps {
	comment?: string;
	image?: string;
	images?: string[];
	onChange: OnChange;
	scheduledFor: Dayjs | undefined;
	onScheduleChange: (value?: Dayjs | null) => void;
	peakTime?: boolean;
	shareEvent?: ShareEvent;
	redirectOnDelete?: boolean;
	uploadImage: (
		options?: MutationFunctionOptions<{uploadImage: string}, OperationVariables, DefaultContext>
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	) => Promise<FetchResult<any>>;
	createDesignJob: (designId: string) => void;
	disabled?: boolean;
	multiImage?: boolean;
	aiEnabled?: boolean;
}

export const TopToolbar = (props: TopToolbarProps) => {
	const {activeNetwork, availableNetworks, onChange} = props;
	const toggleOptions = postServices
		.filter(s => availableNetworks?.includes(s))
		?.map(s => ({value: s, icon: iconMapper[s] || s}));
	const onNetworkChange = useCallback((network: PostServices) => onChange("activeNetwork", network), [
		onChange,
	]);

	if (!toggleOptions?.length) return null;

	return (
		<IconToggleGroup
			className={styles.toggleNetwork}
			options={toggleOptions}
			value={activeNetwork}
			onChange={onNetworkChange}
		/>
	);
};

export const BottomToolbar = ({
	comment,
	image,
	images,
	onChange,
	redirectOnDelete,
	onScheduleChange,
	scheduledFor,
	peakTime,
	shareEvent,
	uploadImage,
	createDesignJob,
	disabled,
	multiImage,
	aiEnabled,
}: BottomToolbarProps) => {
	const {values} = useFormikContext<FormValues>();
	const {id, url, recipient, schedule, expiresAt, activeNetwork, opengraphs} = values;
	const [deleteShareEvent, {loading: deleting}] = useMutationToast(DELETE_SHARE_EVENT);
	const [peakTimeforShare, {loading: loadingPeakTime}] = useMutationToast(GENERATE_PEAK_TIME);
	const [uploading, setUploading] = useState(false);
	const toast = useToast();
	const [calendarOpen, setCalendarOpen] = useState(false);
	const navigate = useNavigate();
	const tabs = useMemo<("image" | "video")[]>(() => (url || multiImage ? ["image"] : ["image", "video"]), [
		url,
		multiImage,
	]);
	const activeTitle = useMemo(() => opengraphs?.[activeNetwork]?.title, [activeNetwork, opengraphs]);
	const handleMediaUpdate = useCallback(
		(selectedMedia: Media[]) => {
			if (!selectedMedia[0]) return;

			if (selectedMedia[0]?.type === "video") {
				onChange("image", selectedMedia[0].thumbnailUrl);
				onChange("video", selectedMedia[0].url);
				if (activeTitle === "Text Share") {
					onChange("title", null);
				}
				return;
			}

			const newImages = selectedMedia.filter(m => m.type === "image").map(m => m.url);

			if (!newImages.length) return;

			const allImages = [...(image ? [image] : []), ...(images ?? []), ...newImages].slice(
				0,
				networkMaxImages[activeNetwork || "general"]
			);

			onChange("image", !url ? allImages[0] : newImages[0]);
			onChange("images", !url ? allImages.slice(1) : undefined);
			onChange("video", undefined);
		},
		[onChange, image, images, activeNetwork, url, activeTitle]
	);

	const onCanvaItemSelect = useCallback(
		designId => {
			createDesignJob(designId);
		},
		[createDesignJob]
	);
	const onFileChange = useCallback(
		async (files: File[]) => {
			if (!files) return;

			const imgFiles = files.filter(file => file.type.split("/")?.[0] === "image");

			if (!imgFiles.length) {
				return;
			}

			setUploading(true);

			const existingImages = [...(image ? [image] : []), ...(images ?? [])];

			const results = await Promise.all(
				imgFiles
					.slice(0, networkMaxImages[activeNetwork || "general"] - existingImages.length)
					.map(file => uploadImage({variables: {file}}))
			).catch(errors => toast({color: "red", text: errors[0], icon: "warning"}));

			setUploading(false);

			if (!results) return;

			const newImages = results.map(({data}) => data?.uploadImage);

			const allImages = [...existingImages, ...newImages].slice(
				0,
				networkMaxImages[activeNetwork || "general"]
			);

			onChange("image", !url ? allImages[0] : newImages[0]);
			onChange("images", !url ? allImages.slice(1) : undefined);
			onChange("video", undefined);
		},
		[toast, uploadImage, onChange, image, images, activeNetwork, setUploading, url]
	);
	const handleDelete = useCallback(
		close => {
			deleteShareEvent({variables: {id: shareEvent?.id}}).then(() => {
				close();
				if (redirectOnDelete) navigate("/collections/posts", {state: {}});
			});
		},
		[deleteShareEvent, shareEvent, navigate, redirectOnDelete]
	);
	const deleteModal = useConfirmDeleteModal({
		what: `${accountNames[shareEvent?.network || "linkedin"]} share`,
		onDelete: handleDelete,
		deleting: deleting,
	});

	const handleUsePeakTime = useCallback(async () => {
		let scheduledFor: Dayjs | undefined;

		if (recipient?.peakTime && shareEvent) {
			const response = await peakTimeforShare({
				variables: {id, networks: [shareEvent.network], timezone: dayjs.tz.guess()},
			});
			scheduledFor = dayjs(response?.data?.peakTimeforShare?.[0]?.scheduledFor);
		}

		onScheduleChange(scheduledFor);
		setCalendarOpen(false);
	}, [onScheduleChange, recipient, shareEvent, id, peakTimeforShare]);

	return (
		<div className={styles.editorBottomToolbar}>
			<div className={styles.buttonsContainer}>
				<MediaButton
					onFileChange={onFileChange}
					onCanvaSelect={onCanvaItemSelect}
					multiple={!url}
					loading={uploading}
					mediaLibrary={{
						onConfirm: handleMediaUpdate,
						tabs: tabs,
						multiple: !url,
					}}
					disabled={disabled}
				/>
				<EmojiPickerInput value={""} onChange={v => onChange("emoji", v)} disabled={disabled} />
				{aiEnabled && (
					<AITextPicker
						value={comment || ""}
						label="Comment"
						onChange={v => onChange("comment", v)}
						disabled={disabled}
					/>
				)}
				{!schedule?.immediately && (
					<>
						<div className={styles.buttonsSeparator} />
						<HoverTooltip text={"Schedule"} positions={["top"]}>
							<div className={styles.schedulerContainer} onClick={() => setCalendarOpen(true)}>
								<PeakTimeCalendar
									min={dayjs()}
									max={expiresAt ? dayjs(expiresAt) : undefined}
									peakTime={recipient?.peakTime}
									isOpen={calendarOpen}
									onOpen={setCalendarOpen}
									onChange={onScheduleChange}
									onUsePeakTime={handleUsePeakTime}
									showClear={!shareEvent}
									value={scheduledFor ?? dayjs().add(1, "day")}
								/>
								<Button
									invert
									border={false}
									color="black"
									value=""
									icon="schedule"
									onClick={() => setCalendarOpen(value => !value)}
									loading={loadingPeakTime}
								/>
								{peakTime && !scheduledFor ? (
									<Span2 color="pink">Schedule using PeakTime™</Span2>
								) : !scheduledFor ? (
									<Span2 color="black">Not scheduled</Span2>
								) : (
									<Span2 color={dayjs(scheduledFor).isBefore() ? "pink" : "black"}>
										{dayjs(scheduledFor).format("MMM DD, YYYY, h:mm A")}
									</Span2>
								)}
							</div>
						</HoverTooltip>
					</>
				)}
			</div>
			{shareEvent && (
				<HoverTooltip text={"Remove Share"} positions={["top"]}>
					<Button
						onClick={deleteModal.open}
						icon="delete"
						value=""
						color="black"
						invert
						border={false}
						loading={deleting}
					/>
				</HoverTooltip>
			)}
		</div>
	);
};
