import {gql} from "@apollo/client";
import {Dayjs} from "dayjs";

import {Service, loadDate, clearTypename} from ".";
import {OPENGRAPHS_FRAGMENT, OpenGraphs} from "./opengraph";

export type ShareStatus = "scheduled" | "delivered" | "failed";

export const ShareSort = {
	created: "CREATED",
	scheduled: "SCHEDULED",
} as const;

type ShareEventResultData = {
	errorMessage?: string;
	id?: string;
	updateUrl?: string;
};
export interface ShareEvent {
	id: number;
	share: Share;
	network: Service;
	scheduledAt: Dayjs;
	scheduledFor: Dayjs;
	sharedAt?: Dayjs;
	linkCode: string;
	socialScore: number;
	result: boolean;
	resultData?: ShareEventResultData;
}

export interface Share {
	id: number;
	companyId: number;
	userId: number;
	opengraphs: OpenGraphs;
	url: string | null | undefined;
	smartScoreSuggestions: SmartScoreSuggestions;
	shareEvents: ShareEvent[];
	fromCollection: boolean;
}

export interface SmartScoreSuggestions {
	general: SmartScoreSuggestion[];
	facebook?: SmartScoreSuggestion[];
	instagram?: SmartScoreSuggestion[];
	linkedin?: SmartScoreSuggestion[];
	twitter?: SmartScoreSuggestion[];
}

export interface SmartScoreSuggestion {
	message: string;
	trigger: string;
	value: number;
}

export interface SocialScoreAndSuggestions {
	socialScore: number;
	suggestions: SmartScoreSuggestions;
}

export interface NetworkPeakTime {
	network: Service;
	scheduledFor: Dayjs;
}

export interface EventResult {
	message: string;
	success: boolean;
	url?: string;
}

export type UserShareEventsResult = Record<Service, EventResult>;

export interface SavedShareParams {
	queueId: number;
	queuedUrlId: number;
	companyUserId: number;
	url: string;
	queue_url: string;
}

export const SMART_SCORE_SUGGESTIONS_FRAGMENT = gql`
	fragment SmartScoreSuggestion on SmartScoreSuggestion {
		message
		trigger
		value
	}
	fragment SmartScoreSuggestionsFields on SmartScoreSuggestions {
		general {
			...SmartScoreSuggestion
		}
		facebook {
			...SmartScoreSuggestion
		}
		instagram {
			...SmartScoreSuggestion
		}
		linkedin {
			...SmartScoreSuggestion
		}
		twitter {
			...SmartScoreSuggestion
		}
	}
`;

export const SHARE_FRAGMENT = gql`
	fragment ShareFields on Share {
		id
		companyId
		userId
		opengraphs {
			...OpenGraphsFields
		}
		url
		smartScoreSuggestions {
			...SmartScoreSuggestionsFields
		}
		fromCollection
	}
	${OPENGRAPHS_FRAGMENT}
	${SMART_SCORE_SUGGESTIONS_FRAGMENT}
`;

export const SHARE_EVENT_FRAGMENT = gql`
	fragment ShareEventFields on ShareEvent {
		id
		network
		scheduledAt
		scheduledFor
		sharedAt
		linkCode
		socialScore
		result
		resultData {
			id
			updateUrl
			errorMessage
		}
	}
`;

export const SHARE_EVENTS_RESULT_FRAGMENT = gql`
	fragment EventResultFields on EventResult {
		message
		success
		url
	}
	fragment ShareEventResultFields on UserShareEventsResult {
		facebook {
			...EventResultFields
		}
		linkedin {
			...EventResultFields
		}
		twitter {
			...EventResultFields
		}
		instagram {
			...EventResultFields
		}
	}
`;

const SHARE_EVENT_WITH_SHARE_FRAGMENT = gql`
	fragment ShareEventWithShareFields on ShareEvent {
		...ShareEventFields
		share {
			...ShareFields
		}
	}
	${SHARE_FRAGMENT}
	${SHARE_EVENT_FRAGMENT}
`;

export const SHARE_WITH_SHARE_EVENT_FRAGMENT = gql`
	fragment ShareWithShareEventFields on Share {
		...ShareFields
		shareEvents {
			...ShareEventFields
		}
	}
	${SHARE_FRAGMENT}
	${SHARE_EVENT_FRAGMENT}
`;

const PEAK_TIME_FRAGMENT = gql`
	fragment PeakTimeFields on NetworkScheduledFor {
		network
		scheduledFor
	}
`;

export const SAVED_SHARE_PARAMS = gql`
	fragment SavedShareFields on SavedShareParams {
		queueId
		queuedUrlId
		companyUserId
		url
		queue_url
	}
`;

export const SOCIAL_SCORE_AND_SUGGESTION_FRAGMENT = gql`
	fragment SocialScoreAndSuggestionsFields on SocialScoreAndSuggestions {
		socialScore
		suggestions {
			...SmartScoreSuggestionsFields
		}
	}
	${SMART_SCORE_SUGGESTIONS_FRAGMENT}
`;

export const GET_COMPANY_SHARE_EVENTS = gql`
	query CompanyShareEvents(
		$cursor: String
		$ids: [PositiveInt!]
		$limit: NonNegativeInt
		$status: [ShareStatus!]
		$sort: ShareSort
		$start: DateTime
		$end: DateTime
		$search: String
	) {
		companyShareEvents(
			cursor: $cursor
			ids: $ids
			limit: $limit
			status: $status
			sort: $sort
			start: $start
			end: $end
			search: $search
		) {
			cursor
			items {
				...ShareEventWithShareFields
			}
		}
	}
	${SHARE_EVENT_WITH_SHARE_FRAGMENT}
`;

export const GET_USER_SHARE_EVENTS = gql`
	query UserShareEvents(
		$cursor: String
		$limit: NonNegativeInt
		$status: [ShareStatus!]
		$sort: ShareSort
		$start: DateTime
		$end: DateTime
		$search: String
	) {
		userShareEvents(
			cursor: $cursor
			limit: $limit
			status: $status
			sort: $sort
			start: $start
			end: $end
			search: $search
		) {
			cursor
			items {
				...ShareEventWithShareFields
			}
		}
	}
	${SHARE_EVENT_WITH_SHARE_FRAGMENT}
`;

export const GET_USER_EVENTS = gql`
	query ShareEvents($ids: [PositiveInt!]!) {
		userEvents(ids: $ids) {
			...ShareEventWithShareFields
		}
	}
	${SHARE_EVENT_WITH_SHARE_FRAGMENT}
`;

export const SCHEDULE_SHARE_EVENT = gql`
	mutation ScheduleShareEvents($id: PositiveInt!, $companyId: PositiveInt, $timezone: String) {
		scheduleSharesByQueueUrlId(id: $id, companyId: $companyId, timezone: $timezone) {
			...ShareWithShareEventFields
		}
	}
	${SHARE_WITH_SHARE_EVENT_FRAGMENT}
`;

export const SCHEDULE_QUEUE = gql`
	mutation ScheduleQueue($id: PositiveInt!, $companyId: PositiveInt, $timezone: String) {
		scheduleSharesByQueueId(id: $id, companyId: $companyId, timezone: $timezone) {
			...ShareWithShareEventFields
		}
	}
	${SHARE_WITH_SHARE_EVENT_FRAGMENT}
`;

export const CREATE_SHARE = gql`
	mutation CreateShare(
		$networks: [ShareNetwork!]!
		$url: String
		$opengraphs: OpenGraphsInput!
		$timezone: String
	) {
		createShare(networks: $networks, url: $url, opengraphs: $opengraphs, timezone: $timezone) {
			...ShareWithShareEventFields
		}
	}
	${SHARE_WITH_SHARE_EVENT_FRAGMENT}
`;

export const CREATE_COMPANY_SHARE = gql`
	mutation CreateCompanyShare(
		$companyId: PositiveInt!
		$networks: [ShareNetwork!]!
		$url: String
		$opengraphs: OpenGraphsInput!
		$timezone: String
	) {
		createCompanyShare(
			companyId: $companyId
			networks: $networks
			url: $url
			opengraphs: $opengraphs
			timezone: $timezone
		) {
			...ShareWithShareEventFields
		}
	}
	${SHARE_WITH_SHARE_EVENT_FRAGMENT}
`;

export const DUPLICATE_COMPANY_SHARE = gql`
	mutation DuplicateCompanyShare($id: PositiveInt!, $companyId: PositiveInt!) {
		duplicateCompanyShare(id: $id, companyId: $companyId) {
			...ShareWithShareEventFields
		}
	}
	${SHARE_WITH_SHARE_EVENT_FRAGMENT}
`;

export const DUPLICATE_SHARE = gql`
	mutation DuplicateShare($id: PositiveInt!) {
		duplicateShare(id: $id) {
			...ShareWithShareEventFields
		}
	}
	${SHARE_WITH_SHARE_EVENT_FRAGMENT}
`;

export const DELETE_SHARE_EVENT = gql`
	mutation DeleteShareEvent($id: PositiveInt!) {
		deleteShareEvent(id: $id) {
			...ShareEventFields
		}
	}
	${SHARE_EVENT_FRAGMENT}
`;

export const GET_SHARE = gql`
	query Share($id: PositiveInt!) {
		share(id: $id) {
			...ShareWithShareEventFields
			expiresAt
		}
	}
	${SHARE_WITH_SHARE_EVENT_FRAGMENT}
`;

export const UPDATE_SHARE_NETWORK = gql`
	mutation UpdateShareNetwork($id: PositiveInt!, $services: [String!]!) {
		updateShareNetwork(id: $id, services: $services) {
			...ShareWithShareEventFields
		}
	}
	${SHARE_WITH_SHARE_EVENT_FRAGMENT}
`;

export const UPDATE_SHARE = gql`
	mutation UpdateShare($id: PositiveInt!, $changes: ShareInput!, $deletions: ShareDeletions, $url: String) {
		updateShare(id: $id, changes: $changes, deletions: $deletions, url: $url) {
			...ShareWithShareEventFields
		}
	}
	${SHARE_WITH_SHARE_EVENT_FRAGMENT}
`;

export const UPDATE_SHARE_EVENT = gql`
	mutation UpdateShareEvent($id: PositiveInt!, $scheduledFor: DateTime) {
		updateShareEvent(id: $id, scheduledFor: $scheduledFor) {
			...ShareEventFields
		}
	}
	${SHARE_EVENT_FRAGMENT}
`;

export const GENERATE_PEAK_TIME = gql`
	mutation PeakTimeforShare($id: PositiveInt!, $networks: [String!], $timezone: String) {
		peakTimeforShare(id: $id, networks: $networks, timezone: $timezone) {
			...PeakTimeFields
		}
	}
	${PEAK_TIME_FRAGMENT}
`;

export const GET_NETWORK_TIME_SLOTS = gql`
	query GetNetworkTimeSlots($networks: [String!], $expiresAt: DateTime, $userId: PositiveInt) {
		timeSlotsForNetwork(networks: $networks, expiresAt: $expiresAt, userId: $userId) {
			network
			scheduledFor
		}
	}
`;

export const GET_NETWORK_PEAK_TIME = gql`
	query GetNetworkPeakTime($networks: [String!], $expiresAt: DateTime, $userId: PositiveInt) {
		peakTimeForNetwork(networks: $networks, expiresAt: $expiresAt, userId: $userId) {
			network
			scheduledFor
		}
	}
`;

export const SHARE_IMMEDIATELY = gql`
	mutation ShareImmediately($id: PositiveInt!) {
		shareImmediately(id: $id) {
			...ShareEventResultFields
		}
	}
	${SHARE_EVENTS_RESULT_FRAGMENT}
`;

export const GET_SAVED_SHARE_PARAMS = gql`
	query SavedShareParams {
		savedShareParams {
			...SavedShareFields
		}
	}
	${SAVED_SHARE_PARAMS}
`;

export const CREATE_SHARE_LINK = gql`
	mutation CreateShareLink($url: String!) {
		createShareLink(url: $url)
	}
`;

export const CALCULATE_SOCIAL_SCORE = gql`
	mutation SocialScoreAndSuggestions($opengraphs: OpenGraphsInput, $services: [String!]) {
		socialScoreAndSuggestions(opengraphs: $opengraphs, services: $services) {
			...SocialScoreAndSuggestionsFields
		}
	}
	${SOCIAL_SCORE_AND_SUGGESTION_FRAGMENT}
`;

export const loadShareEvent = (event): ShareEvent => ({
	...event,
	scheduledAt: loadDate(event.scheduledAt),
	scheduledFor: loadDate(event.scheduledFor),
	sharedAt: loadDate(event.sharedAt),
});

export const loadShare = (share): Share => {
	const ret: Share = {
		...share,
		opengraphs: clearTypename(share.opengraphs),
	};
	if (share.shareEvents?.length) ret.shareEvents = share.shareEvents.map(loadShareEvent);
	return ret;
};
